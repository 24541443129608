import React from 'react'
import styled from 'styled-components'
import HttpError from 'standard-http-error'

import {NetworkError} from '../helpers/api'
import Layout from '../layout'
import ROCK from '../images/rock.svg'

const Center = styled.p`
  text-align: center;
  img {
    max-width: 100%;
  }
`

interface Props {
  error: Error
}

const ErrorTemplate: React.FC<Props> = ({error}) => {
  let title = 'Erreur'
  let text = 'Un problème est survenu. Essayez de recharger la page.'
  if (error instanceof HttpError) {
    if (error.code === 404) {
      title = 'Page introuvable'
      text = 'Erreur 404'
    } else {
      text = error.message
    }
  } else if (error instanceof NetworkError) {
    text = 'Connexion impossible'
  }

  return (
    <Layout title={title}>
      <h1>{title}</h1>
      <p>{text}</p>
      <Center>
        <img src={ROCK} alt="" />
      </Center>
    </Layout>
  )
}

export default ErrorTemplate
